import React, { useRef } from 'react';
//import { useRouter } from 'next/router';
import { Button } from 'primereact/button';
import { Menu } from 'primereact/menu';
import { PanelMenu } from 'primereact/panelmenu';
import { Toast } from 'primereact/toast';

export function LeagueMenu( {leagues} ) {
    const menu = useRef(null);
    //const router = useRouter();
    const toast = useRef(null);
    /*const leagueItems = [
      {
          label: 'Europe',
          items: [
              {
                  label: 'Champions League',
                  url:"/league/europe/champions-league"
              },
              {
                  label: 'Europa League',
                  url:"/league/europe/europa-league"
              }
          ]
      },
      {
          label: 'England',
          items: [
              {
                  label: 'Premier League',
                  url:"/league/england/premier-league"
              },
              {
                  label: 'Championship',
                  url:"/league/england/championship"
              }
          ]
      }
    ];*/
    const leagueItems = leagues;
    const items = [
        {
            template: (item, options) => {
                return (
                    <PanelMenu model={leagueItems} multiple/>
                )
        }}
    ];

    return (
        <div className="card inline p-menuitem-link">
            <Toast ref={toast}></Toast>
            <Menu model={items} popup ref={menu} />
            <Button label="" icon="pi pi-bars" onClick={(e) => menu.current.toggle(e)} />
        </div>
    )
}

//export default LeagueMenu;
