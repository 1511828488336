
const timezoneOffset = new Date().getTimezoneOffset();

export async function getMatches({ areaInfo, leagueInfo}) {
  return await fetch(`/serverapi/getMatches/${leagueInfo}${areaInfo}/${timezoneOffset}`, {
    method: "GET",
    headers: { "Content-Type": "application/json" },
  })
    .then((response) => {
      // If request is not successful, display error message
      if (!response.ok) {
        throw new Error("HTTP status " + response.status);
      }

      return response.json();
    })
    .catch((err) => {
      console.log(err);
    });
}

export async function getInitialMatches({ dateInfo}) {
  return await fetch(`/serverapi/getInitialMatches/${dateInfo}/${timezoneOffset}`, {
    method: "GET",
    headers: { "Content-Type": "application/json" },
  })
    .then((response) => {
      // If request is not successful, display error message
      if (!response.ok) {
        throw new Error("HTTP status " + response.status);
      }

      return response.json();
    })
    .catch((err) => {
      console.log(err);
    });
}

export async function getCalculations({ HomeTeamCode, AwayTeamCode, MatchDate }) {
  //console.log(HomeTeamCode);
  return await fetch(`/serverapi/getCalculations/${HomeTeamCode}${AwayTeamCode}${MatchDate}`, {
    method: "GET",
    headers: { "Content-Type": "application/json" },
  })
    .then((response) => {
      // If request is not successful, display error message
      if (!response.ok) {
        throw new Error("HTTP status " + response.status);
      }

      return response.json();
    })
    .catch((err) => {
      console.log(err);
    });
}

export async function getLeagues() {
  return await fetch(`/serverapi/getLeagues`, {
    method: "GET",
    headers: { "Content-Type": "application/json" },
  })
    .then((response) => {
      // If request is not successful, display error message
      if (!response.ok) {
        throw new Error("HTTP status " + response.status);
      }

      return response.json();
    })
    .catch((err) => {
      console.log(err);
    });
}

export async function postUserPred(data) {
  return await fetch(`/serverapi/postUserPred`, {
    method: "POST",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify(data),
    cache: "no-cache"
  })
    .then((response) => {
      // If request is not successful, display error message
      if (!response.ok) {
        throw new Error("HTTP status " + response.status);
      }
      return response;
    })
    .catch((err) => {
      console.log(err);
    });
}

export async function getUserPicks(userCookie) {
  return await fetch(`/serverapi/getUserPicks/${userCookie}/${timezoneOffset}`, {
    method: "GET",
    headers: { "Content-Type": "application/json" },
  })
    .then((response) => {
      // If request is not successful, display error message
      if (!response.ok) {
        throw new Error("HTTP status " + response.status);
      }

      return response.json();
    })
    .catch((err) => {
      console.log(err);
    });
}

export async function deleteUserPicks(userCookie, data) {
  return await fetch(`/serverapi/deleteUserPicks/${userCookie}`, {
    method: "POST",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify(data),
    cache: "no-cache"
  })
    .then((response) => {
      // If request is not successful, display error message
      if (!response.ok) {
        throw new Error("HTTP status " + response.status);
      }
      return response;
    })
    .catch((err) => {
      console.log(err);
    });
}
