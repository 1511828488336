import React, { useState, useEffect } from 'react';
import { InputNumber } from 'primereact/inputnumber';
import { InputText } from "primereact/inputtext";
import { Button } from "primereact/button";
import { MatchService } from '../service/matchService';
import { useCookies } from 'react-cookie';
import { v4 as uuid } from 'uuid';

export function PopupForm( {match, opRef} ) {
  const [amount, setAmount] = useState(0);
  const [odds, setOdds] = useState(match.Odds);
  const [payout, setPayout] = useState(0);
  const [userCookie, setUserCookie] = useCookies(['user']);
  var currentAmount = 0;
  var currentOdds = match.Odds;

  const setNewAmount = (e) => {
    setAmount(e.target.value);
    currentAmount = e.target.value;
    currentOdds = odds;
    setPayout(currentOdds * currentAmount);
  };

  const setNewOdds = (e) => {
    setOdds(e.target.value);
    currentOdds = e.target.value;
    currentAmount = amount;
    setPayout(currentOdds * currentAmount);
  };

  const handleFormSubmit = (e) => {
    var userCook;
    if (userCookie.UserToken) {
      userCook = userCookie.UserToken;
    }
    else {
      userCook = uuid();
      setUserCookie('UserToken', userCook, { path: '/'});
    }

    const userPred = {"ResultValue": match.ResultValue, "Odds": odds, "Amount": amount, "PotentialPayout": payout,
                        "ResultPicked": match.Result,
                        "HomeTeamCode": match.HomeTeamCode, "AwayTeamCode": match.AwayTeamCode,
                        "HomeImagePath": match.HomeTeamImagePath, "AwayImagePath": match.AwayTeamImagePath,
                        "LeagueImagePath": match.CountryImagePath, "MatchId": match.PredId,
                        "MatchTime": match.MatchTimeRaw, "League": match.League,
                        "UserCookie": userCook, "PredId": -1}
    e.preventDefault();
    MatchService.submitUserPred(userPred);
    opRef.current.toggle();
  };

  return (
    <div className="flex-auto">
      <form onSubmit={handleFormSubmit}>
        <label> Home Team:
          <InputText disabled defaultValue={match.HomeTeamCode} />
        </label>
        <br/>
        <label> Away Team:
          <InputText disabled defaultValue={match.AwayTeamCode} />
        </label>
        <br/>
        <label> Match Time:
          <InputText disabled defaultValue={match.MatchTime} />
        </label>
        <br/>
        <label> Selected:
          <InputText disabled defaultValue={match.Result} />
        </label>
        <br/>
        <label>Bet Amount:
          <InputNumber inputId="amountNum" value={amount} onValueChange={setNewAmount} minFractionDigits={0} maxFractionDigits={2} />
        </label>
        <br/>
        <label>Odds:
          <InputNumber inputId="oddsNum" value={odds} onValueChange={setNewOdds} minFractionDigits={0} maxFractionDigits={2} />
        </label>
        <br/>
        <label>Potential Payout:
          <InputNumber disabled inputId="payoutNum" value={payout} onValueChange={(e) => setPayout(e.target.value)} minFractionDigits={0} maxFractionDigits={2} />
        </label>
        <br/>
        <Button label="Submit" type="submit" icon="pi pi-check" />
        <br/>
        <br/>
        <label> Go to MyPicks to view after submission</label>
        <label> *BetCopaVerde does not offer any payouts. All data listed above is not used for betting. Only used for informational purposes</label>
      </form>
    </div>
  )

}
