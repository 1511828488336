import { getMatches, getInitialMatches, postUserPred, getUserPicks, deleteUserPicks } from "../api/";

export const MatchService = {
  getMatchesOnLoad (areaInfo, leagueInfo, dateInfo) {
    if ((areaInfo === "" && leagueInfo === "" && dateInfo === "") || (areaInfo === undefined && leagueInfo === undefined && dateInfo === undefined)) {
      const matches = getInitialMatches({});
      return matches;
    }
    else if (dateInfo !== "" && dateInfo !== undefined) {
      const matches = getInitialMatches({dateInfo});
      return matches;
    }
    else {
      const matches = getMatches({leagueInfo, areaInfo});
      return matches;
    }
  },

  submitUserPred (userPred) {
    postUserPred(userPred);
  },

  getUserPicks (userCookie) {
    const userPicks = getUserPicks(userCookie);
    return userPicks;
  },

  deleteUserPicks (userCookie, matchesToDelete) {
    deleteUserPicks(userCookie, matchesToDelete);
  }

};

/*
function getHeaderName () {
  if ((areaInfo === "" && leagueInfo === "" && dateInfo === "") || (areaInfo === undefined && leagueInfo === undefined && dateInfo === undefined)) {
    return "Today's Matches"
  }
  else if (dateInfo !== "" && dateInfo !== undefined) {
    return "Matches For ".concat(dateInfo)
  }
  else {
    //var capArea = getCapitalizedName(areaInfo);
    var capLeague = getCapitalizedName(leagueInfo);
    return "Upcoming Matches For ".concat(capLeague)
  }
}
getProductsData() {
    return [];
},

getProductsWithOrdersData() {
    return [];
},

getProductsMini() {
    return Promise.resolve(this.getProductsData().slice(0, 5));
},

getProductsSmall() {
    return Promise.resolve(this.getProductsData().slice(0, 10));
},

getProducts() {
    return Promise.resolve(this.getProductsData());
},

getProductsWithOrdersSmall() {
    return Promise.resolve(this.getProductsWithOrdersData().slice(0, 10));
},

getProductsWithOrders() {
    return Promise.resolve(this.getProductsWithOrdersData());
}*/
