import React, { useState, useEffect, useRef } from 'react';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { MatchService } from '../service/matchService';
import { InputNumber } from 'primereact/inputnumber';
import { Button } from "primereact/button";
import defaultImage from '../assets/img/Default.jpg';

export function PickTable( {userCookie} ) {
  const [picks, setPicks] = useState([]);
  const [expandedRows, setExpandedRows] = useState([]);
  const [selectedMatch, setSelectedMatch] = useState();

  function processUserPicks (userPicks) {
    userPicks.forEach((item, i) => {
      item["outcomes"] = [{"Result": item.HomeTeamCode, "Prob": item.WinProb, "Odds": item.WinOdds,
                            "OddsPred": item.WinOddsPred, "Favor": item.WinFavor, "Image": item.HomeImagePath,
                            "ResultValue": "H", "HomeTeamCode": item.HomeTeamCode, "AwayTeamCode": item.AwayTeamCode,
                            "MatchTime": item.MatchTime, "League": item.League, "PredId": item.PredId},
                          {"Result": item.AwayTeamCode, "Prob": item.LossProb, "Odds": item.LossOdds,
                            "OddsPred": item.LossOddsPred, "Favor": item.LossFavor, "Image": item.AwayImagePath,
                            "ResultValue": "A", "HomeTeamCode": item.HomeTeamCode, "AwayTeamCode": item.AwayTeamCode,
                            "MatchTime": item.MatchTime, "League": item.League, "PredId": item.PredId},
                          {"Result": "Draw", "Prob": item.DrawProb, "Odds": item.DrawOdds,
                            "OddsPred": item.DrawOddsPred, "Favor": item.DrawFavor, "Image": "",
                            "ResultValue": "D", "HomeTeamCode": item.HomeTeamCode, "AwayTeamCode": item.AwayTeamCode,
                            "MatchTime": item.MatchTime, "League": item.League, "PredId": item.PredId}]
    });
    setPicks(userPicks);
  }

  function getCapitalizedName (name) {
    return name.replace(/-/g, ' ').replace(/\w\S*/g, function(txt){
        return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
    });
  };

  useEffect(() => {
      MatchService.getUserPicks(userCookie).then(data => processUserPicks(data));
  }, []);

  const countryBodyTemplate = (match) => {
     return <div className="flex align-items-center">
              <img className="team-image w-2rem py-3" src={`https://betcopaverde-bucket.s3.amazonaws.com/${match.LeagueImagePath}.jpg`} alt={defaultImage}/>
              <td className="p-3">{getCapitalizedName(match.League)}</td>
            </div>;
  };

  const homeTeamBodyTemplate = (match) => {
     return <div className="flex align-items-center">
              <img className="team-image w-2rem py-3" src={`https://betcopaverde-bucket.s3.amazonaws.com/${match.HomeImagePath}.jpg`} alt={defaultImage}/>
              <td className="p-3">{getCapitalizedName(match.HomeTeamCode)}</td>
            </div>;
  };

  const awayTeamBodyTemplate = (match) => {
     return <div className="flex align-items-center">
              <img className="team-image w-2rem py-3" src={`https://betcopaverde-bucket.s3.amazonaws.com/${match.AwayImagePath}.jpg`} alt={defaultImage}/>
              <td className="p-3">{getCapitalizedName(match.AwayTeamCode)}</td>
            </div>;
  };

  const outcomeTeamBodyTemplate = (outcome) => {
    if (outcome.Image !== "") {
       return <div className="flex align-items-center">
                <img className="team-image w-2rem py-3" id={outcome.PredId} matchodds={outcome.Odds} src={`https://betcopaverde-bucket.s3.amazonaws.com/${outcome.Image}.jpg`} alt={defaultImage}/>
                <td className="p-3" id={outcome.PredId} matchodds={outcome.Odds}>{getCapitalizedName(outcome.Result)}</td>
              </div>;
    }
    else {
       return <div className="flex align-items-center">
                <td className="p-3 pl-6" id={outcome.PredId} matchodds={outcome.Odds}>{getCapitalizedName(outcome.Result)}</td>
              </div>;
    }
  };

  const predictionTemplate = (pick) => {
    if (pick.ResultValue === "H") {
       return <div className="flex align-items-center">
                <img className="team-image w-2rem py-3" src={`https://betcopaverde-bucket.s3.amazonaws.com/${pick.HomeImagePath}.jpg`} alt={defaultImage}/>
                <td className="p-3" id={pick.PredId}>{getCapitalizedName(pick.HomeTeamCode)}</td>
              </div>;
    }
    else if (pick.ResultValue === "A") {
       return <div className="flex align-items-center">
                <img className="team-image w-2rem py-3" src={`https://betcopaverde-bucket.s3.amazonaws.com/${pick.AwayImagePath}.jpg`} alt={defaultImage}/>
                <td className="p-3" id={pick.PredId}>{getCapitalizedName(pick.AwayTeamCode)}</td>
              </div>;
    }
    else {
       return <div className="flex align-items-center">
                <td className="p-3 pl-6">Draw</td>
              </div>;
    }
  };

  const allowExpansion = (rowData) => {
      return true;
  };

  const onRowEditComplete = (e) => {
      let _picks = [...picks];
      let { newData, index } = e;

      _picks[index] = newData;
      _picks[index]['PotentialPayout'] = _picks[index]['Odds'] * _picks[index]['Amount'];

      setPicks(_picks);
      _picks[index]['UserCookie'] = userCookie;
      MatchService.submitUserPred(_picks[index]);
  };

  const rowExpansionTemplate = (data) => {
      return (
          <div className="p-3">
            <DataTable value={data.outcomes}>
              <Column field="Result" header="Result" body={outcomeTeamBodyTemplate}></Column>
              <Column field="Prob" header="Probability"></Column>
              <Column field="Odds" header="Odds"></Column>
              <Column field="OddsPred" header="Calc Odds"></Column>
              <Column field="Favor" header="Favor"></Column>
            </DataTable>
          </div>
      );
  };

  const priceEditor = (options) => {
      return <InputNumber value={options.value} onValueChange={(e) => options.editorCallback(e.value)} minFractionDigits={0} maxFractionDigits={2} />;
  };

  const selectionHeaderTemplate = () => {
    return <Button label="Delete" onClick={deleteRecords} severity="danger" icon="pi pi-trash" />
  }

  const deleteRecords = (e) => {
    MatchService.deleteUserPicks(userCookie, selectedMatch);
    //MatchService.getUserPicks(userCookie).then(data => processUserPicks(data));
    let _deleteRecords = selectedMatch;
    var ids_to_delete = [];
    _deleteRecords.forEach((item, i) => {
      ids_to_delete.push(item["PredId"]);
    });

    let _picks = [...picks];
    var new_picks = []
    _picks.forEach((item, i) => {
      if (!ids_to_delete.includes(item["PredId"])) {
        new_picks.push(item);
      }
    });

    setPicks(new_picks);
  };

  return (
    <div className="card">
        <DataTable value={picks} stripedRows
              sortMode="single" sortField="code" sortOrder={1}
              expandedRows={expandedRows} onRowToggle={(e) => setExpandedRows(e.data)}
              rowExpansionTemplate={rowExpansionTemplate}
              selection={selectedMatch}
              tableStyle={{ minWidth: '50rem' }}
              editMode="row" onRowEditComplete={onRowEditComplete}
              selectionMode={null} onSelectionChange={(e) => setSelectedMatch(e.value)}>
           <Column selectionMode="multiple"></Column>
           <Column rowEditor bodyStyle={{ textAlign: 'center' }} header={selectionHeaderTemplate}></Column>
           <Column field="ResultValue" header="Prediction" body={predictionTemplate}></Column>
           <Column field="Amount" header="Amount" editor={(options) => priceEditor(options)}></Column>
           <Column field="Odds" header="Odds Taken" editor={(options) => priceEditor(options)}></Column>
           <Column field="PotentialPayout" header="Potential Payout"></Column>
           <Column field="PredId" hidden={true}></Column>
           <Column field="League" header="League" body={countryBodyTemplate}></Column>
           <Column expander={allowExpansion} style={{ width: '5rem' }} />
           <Column field="MatchTime" header="Match Time"></Column>
           <Column field="HomeTeamCode" header="Home" body={homeTeamBodyTemplate}></Column>
           <Column field="AwayTeamCode" header="Away" body={awayTeamBodyTemplate}></Column>
        </DataTable>
    </div>
  );
}
