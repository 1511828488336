import React from 'react';
import { LeagueMenu } from './leagueMenu';
import logoImage from '../assets/img/bet_copa_verde_rectangle_transparent.png';
import defaultImage from '../assets/img/Default.jpg';

export function HeaderStart( {leagues} ) {
    function getCapitalizedName (name) {
      return name.replace(/-/g, ' ').replace(/\w\S*/g, function(txt){
          return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
      });
    };

    const leagueDict = {};
    const leagueItems = [];
    leagues.forEach((item, i) => {
      if (item.area in leagueDict) {
        leagueItems[leagueDict[item.area]]["items"].push({label: getCapitalizedName(item.league), url: "/league/".concat(item.area).concat("/").concat(item.league)});
      }
      else {
        leagueDict[item.area] = leagueItems.length;
        leagueItems.push({
          label: getCapitalizedName(item.area),
          icon: `https://betcopaverde-bucket.s3.amazonaws.com/${item.ImagePath}.jpg`,
          items: [{label: getCapitalizedName(item.league), url: "/league/".concat(item.area).concat("/").concat(item.league)}]})
      }
    });
    return (
        <div>
            <LeagueMenu leagues={leagueItems}/>
            <img alt="logo" src={logoImage} height="40" className="mr-2 d-inline-block p-menuitem"></img>
        </div>
    )
}


/*
template: (element, options) => {
    return (
      <a className="flex align-items-center">
       <img className="team-image w-2rem py-3" src={`https://betcopaverde-bucket.s3.amazonaws.com/${item.ImagePath}.jpg`} alt={defaultImage}/>
       <td className="p-3">{getCapitalizedName(item.area)}</td>
     </a>
   )
},
*/
