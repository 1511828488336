import React, {useState} from 'react'
import { Calendar } from 'primereact/calendar';
import { useNavigate, redirect } from 'react-router-dom';
import moment from 'moment';

export const MatchCalendar = () => {
  const [dateState, setDateState] = useState(new Date())
  const navigate = useNavigate();

  const changeDate = (e) => {
    setDateState(e.value);
    navigate(`/date/${moment(e.value).format('YYYY-MM-DD')}`);
    //return redirect(`/date/${moment(e.value).format('YYYY-MM-DD')}`);
  }
  return (
    <div className="card flex justify-content-center">
        <Calendar value={dateState} onChange={changeDate} dateFormat="yy-mm-dd" showIcon />
    </div>
  )
}
