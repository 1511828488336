import React, { useState, useEffect, useRef } from 'react';
import {useParams} from 'react-router-dom';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { OverlayPanel } from 'primereact/overlaypanel';
import { Button } from 'primereact/button';
import { Dialog } from 'primereact/dialog';
import { MatchService } from '../service/matchService';
import defaultImage from '../assets/img/Default.jpg';
import { PopupForm } from './popupForm';

export function MatchTable( {isDesktop} ) {
    const [expandedRows, setExpandedRows] = useState([]);
    const [matches, setMatches] = useState([]);
    const [selectedMatch, setSelectedMatch] = useState();
    const [selectedOutcome, setSelectedOutcome] = useState();
    const params = useParams();
    const [area, setArea] = useState(params['area']);
    const [league, setLeague] = useState(params['league']);
    const [date, setDate] = useState(params['date']);
    const areaInfo = params['area'];
    const leagueInfo = params['league'];
    const dateInfo = params['date'];
    const [loading, setLoading] = useState(true);
    const [showPopup, setShowPopup] = useState(false);
    const op = useRef(null);

    if (area !== areaInfo) {
      setArea(areaInfo);
      setLeague(leagueInfo);
      MatchService.getMatchesOnLoad(areaInfo, leagueInfo, dateInfo).then(data => processMatchInfo(data));
    }
    else if (date !== dateInfo) {
      setDate(dateInfo);
      MatchService.getMatchesOnLoad(areaInfo, leagueInfo, dateInfo).then(data => processMatchInfo(data));
    }

    function getCapitalizedName (name) {
      return name.replace(/-/g, ' ').replace(/\w\S*/g, function(txt){
          return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
      });
    };

    function getClassName (result, match) {
      if (result === match.ActualResult) {
        return "match-winner"
      }
      else if (match.ActualResult === 'N') {
        if (result === match.Prediction) {
          return "match-prediction"
        }
        else {
          return "no-winner"
        }
      }
      else {
        return "match-loser"
      }
    }

    function processMatchInfo (matches) {
      matches.forEach((item, i) => {
        item["outcomes"] = [{"Result": item.HomeTeamCode, "Prob": item.WinProb, "Odds": item.WinOdds,
                              "OddsPred": item.WinOddsPred, "Favor": item.WinFavor, "Image": item.HomeTeamImagePath,
                              "ResultValue": "H", "HomeTeamCode": item.HomeTeamCode, "AwayTeamCode": item.AwayTeamCode,
                              "MatchTime": item.MatchTime, "League": item.League, "PredId": item.PredId},
                            {"Result": item.AwayTeamCode, "Prob": item.LossProb, "Odds": item.LossOdds,
                              "OddsPred": item.LossOddsPred, "Favor": item.LossFavor, "Image": item.AwayTeamImagePath,
                              "ResultValue": "A", "HomeTeamCode": item.HomeTeamCode, "AwayTeamCode": item.AwayTeamCode,
                              "MatchTime": item.MatchTime, "League": item.League, "PredId": item.PredId},
                            {"Result": "Draw", "Prob": item.DrawProb, "Odds": item.DrawOdds,
                              "OddsPred": item.DrawOddsPred, "Favor": item.DrawFavor, "Image": "",
                              "ResultValue": "D", "HomeTeamCode": item.HomeTeamCode, "AwayTeamCode": item.AwayTeamCode,
                              "MatchTime": item.MatchTime, "League": item.League, "PredId": item.PredId}]
      });
      setMatches(matches);
    }

    useEffect(() => {
        MatchService.getMatchesOnLoad(areaInfo, leagueInfo, dateInfo).then(data => processMatchInfo(data));
    }, []);

    const countryBodyTemplate = (match) => {
       return <div>
                {isDesktop ? (
                    <div className="flex align-items-center">
                      <img className="team-image w-2rem py-3" src={`https://betcopaverde-bucket.s3.amazonaws.com/${match.CountryImagePath}.jpg`} alt={defaultImage}/>
                      <td className="p-3">{getCapitalizedName(match.League)}</td>
                    </div>
                  ) :
                  (
                    <div className="flex align-items-center">
                      <img className="team-image w-1rem py-3" src={`https://betcopaverde-bucket.s3.amazonaws.com/${match.CountryImagePath}.jpg`} alt={defaultImage}/>
                      <td />
                    </div>
                  )
                }
              </div>;
    };

    const homeTeamBodyTemplate = (match) => {
       return <div>
                {isDesktop ? (
                    <div className="flex align-items-center">
                      <img className="team-image w-2rem py-3" src={`https://betcopaverde-bucket.s3.amazonaws.com/${match.HomeTeamImagePath}.jpg`} alt={defaultImage}/>
                      <td className="p-3">{getCapitalizedName(match.HomeTeamCode)}</td>
                    </div>
                  ) :
                  (
                    <div className="flex align-items-center">
                      <img className="team-image w-1rem py-3" src={`https://betcopaverde-bucket.s3.amazonaws.com/${match.HomeTeamImagePath}.jpg`} alt={defaultImage}/>
                      <td/>
                    </div>
                  )
                }
              </div>;
    };

    const awayTeamBodyTemplate = (match) => {
       return <div>
                {isDesktop ? (
                    <div className="flex align-items-center">
                      <img className="team-image w-2rem py-3" src={`https://betcopaverde-bucket.s3.amazonaws.com/${match.AwayTeamImagePath}.jpg`} alt={defaultImage}/>
                      <td className="p-3">{getCapitalizedName(match.AwayTeamCode)}</td>
                    </div>
                  ) :
                  (
                    <div className="flex align-items-center">
                      <img className="team-image w-1rem py-3" src={`https://betcopaverde-bucket.s3.amazonaws.com/${match.AwayTeamImagePath}.jpg`} alt={defaultImage}/>
                      <td/>
                    </div>
                  )
                }
              </div>;
    };

    const outcomeTeamBodyTemplate = (outcome) => {
      if (outcome.Image !== "") {
         return <div className="flex align-items-center">
                  <Button onClick={outcomeSelection} id={outcome.PredId} matchodds={outcome.Odds}>
                    <img className="team-image w-2rem py-3" id={outcome.PredId} matchodds={outcome.Odds} src={`https://betcopaverde-bucket.s3.amazonaws.com/${outcome.Image}.jpg`} alt={defaultImage}/>
                    <td className="p-3" id={outcome.PredId} matchodds={outcome.Odds}>{getCapitalizedName(outcome.Result)}</td>
                  </Button>
                </div>;
      }
      else {
         return <div className="flex align-items-center">
                  <Button onClick={outcomeSelection} id={outcome.PredId} matchodds={outcome.Odds}>
                    <td className="p-3 pl-6" id={outcome.PredId} matchodds={outcome.Odds}>{getCapitalizedName(outcome.Result)}</td>
                  </Button>
                </div>;
      }
    };

    const winOutcomeTemplate = (match) => {
        return <td className={getClassName('H', match)}>{match.WinProb}</td>;
    };

    const lossOutcomeTemplate = (match) => {
        return <td className={getClassName('A', match)}>{match.LossProb}</td>;
    };

    const drawOutcomeTemplate = (match) => {
        return <td className={getClassName('D', match)}>{match.DrawProb}</td>;
    };

    const allowExpansion = (rowData) => {
        return true;
    };

    const outcomeSelection = (e) => {
      var selectedRecord;
      expandedRows.forEach((item, i) => {
        if (item.PredId == e.target.id) {
          selectedRecord = item;
        }
      });

      selectedRecord['Result'] = e.target.innerText;
      if (e.target.innerText === getCapitalizedName(selectedRecord['HomeTeamCode'])) {
        selectedRecord['ResultValue'] = 'H';
      }
      else if (e.target.innerText === getCapitalizedName(selectedRecord['AwayTeamCode'])) {
        selectedRecord['ResultValue'] = 'A';
      }
      else {
        selectedRecord['ResultValue'] = 'D';
      }
      selectedRecord['Odds'] = e.target.getAttribute("matchodds");
      setSelectedOutcome(selectedRecord);
      op.current.toggle(e);
      //setSelectedOutcome(e.value);
      //setShowPopup(true);
    };


    const rowExpansionTemplate = (data) => {
        return (
            <div className="p-3">
              <DataTable value={data.outcomes}>
                <Column field="Result" header="Result" body={outcomeTeamBodyTemplate}></Column>
                <Column field="Prob" header="Probability"></Column>
                <Column field="Odds" header="Odds"></Column>
                <Column field="OddsPred" header="Calc Odds"></Column>
                <Column field="Favor" header="Favor"></Column>
              </DataTable>
              <OverlayPanel ref={op} showCloseIcon>
                <PopupForm match={selectedOutcome} opRef={op}/>
              </OverlayPanel>
            </div>
        );
    };

    return (
        <div className="card">
            <DataTable value={matches} stripedRows
                sortMode="single" sortField="code" sortOrder={1}
                expandedRows={expandedRows} onRowToggle={(e) => setExpandedRows(e.data)}
                  rowExpansionTemplate={rowExpansionTemplate}
                  selectionMode="single" selection={selectedMatch}
                 >
                <Column field="League" header="League" body={countryBodyTemplate}></Column>
                <Column expander={allowExpansion} style={{ width: '2rem' }} />
                <Column field="MatchTime" header="Match Time"></Column>
                <Column field="HomeTeamCode" header="Home" body={homeTeamBodyTemplate}></Column>
                <Column field="AwayTeamCode" header="Away" body={awayTeamBodyTemplate}></Column>
                <Column field="WinProb" header="W" body={winOutcomeTemplate}></Column>
                <Column field="DrawProb" header="X" body={drawOutcomeTemplate}></Column>
                <Column field="LossProb" header="L" body={lossOutcomeTemplate}></Column>
                <Column field="Amount" header="Amount"></Column>
                <Column field="PredId" hidden={true}></Column>
            </DataTable>
        </div>
    );
}

/*
const pickOutcomeTemplate = (outcome) => {
    return <Button/>;
};
<Column headerStyle={{ width: '4rem' }} body={pickOutcomeTemplate}></Column>
<Dialog header="Header" visible={showPopup} style={{ width: '50vw' }} onHide={() => setShowPopup(false)}>
  <PopupForm match={selectedOutcome}/>
</Dialog>
{showPopup && <PopupForm match={selectedOutcome}/>}
const rowExpansionTemplate = (data) => {
    return (
        <div className="p-3">
        <td>
          <td className="block pb-2 border-bottom-1">W</td>
          <td className="block py-2">A</td>
          <td className="block pt-2">D</td>
        </td>
        <td>
          <td className="block pb-2 border-bottom-1">{data.WinProb}</td>
          <td className="block py-2">{data.LossProb}</td>
          <td className="block pt-2">{data.DrawProb}</td>
        </td>
        <td>
          <td className="block pb-2 border-bottom-1">{data.WinOdds}</td>
          <td className="block py-2">{data.LossOdds}</td>
          <td className="block pt-2">{data.DrawOdds}</td>
        </td>
        <td>
          <td className="block pb-2 border-bottom-1">{data.WinOddsPred}</td>
          <td className="block py-2">{data.LossOddsPred}</td>
          <td className="block pt-2">{data.DrawOddsPred}</td>
        </td>
        <td>
          <td className="block pb-2 border-bottom-1">{data.WinFavor}</td>
          <td className="block py-2">{data.LossFavor}</td>
          <td className="block pt-2">{data.DrawFavor}</td>
        </td>
            <DataTable value={data.outcomes}>
              <Column field="W" header="Result"></Column>
              <Column field="WinProb" header="Probability"></Column>
              <Column field="WinOdds" header="Odds"></Column>
              <Column field="WinOddsPred" header="Calc Odds"></Column>
              <Column field="WinFavor" header="Favor"></Column>
            </DataTable>
        </div>
    );
};

    <h5>Odds Break for {data.name}</h5>
expandableRowGroups expandedRows={expandedRows} onRowToggle={(e) => setExpandedRows(e.data)}
rowGroupHeaderTemplate={headerTemplate} rowGroupFooterTemplate={footerTemplate}
<img alt={data.name} src={`https://primefaces.org/cdn/primereact/images/avatar/${data.image}`} width="32" style={{ verticalAlign: 'middle' }} className="ml-2" />
const groupRowsBy = ["code", "name"]
const multiSortMeta = [{field: 'code', order: 1}, {field: 'name', order: -1}]
*/
